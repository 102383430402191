import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { INITIAL_FEE, PAYMENT_TYPES } from './enums';

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    onClose: () => {}, // Placeholder onClose function
    ...options, // Merge any additional options passed to the function
  };

  let redirectTimeout;

  const handleToastClose = () => {
    clearTimeout(redirectTimeout); // Clear the timeout when the toast is closed
    defaultOptions.onClose(); // Call the original onClose function if provided
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, {
      ...defaultOptions,
      onClose: handleToastClose,
    });
  } else if (type === 'error') {
    toast.error(message, {
      ...defaultOptions,
      onClose: handleToastClose,
    });
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
    return;
  }

  // Redirect automatically after a certain duration if redirectUrl is provided
  if (options.redirectUrl) {
    redirectTimeout = setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.location.href = options.redirectUrl;
      }
    }, defaultOptions.autoClose);
  }
};

export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export const updateDailyPriceOptions = (individualPriceOptions = [], newEntry) => {
  const { startDate, endDate } = newEntry;
  const newStart = moment(startDate);
  const newEnd = moment(endDate);

  const updatedArray = individualPriceOptions.reduce((acc, priceObj) => {
    const currentStart = moment(priceObj.startDate);
    const currentEnd = moment(priceObj.endDate);

    if (newStart.isBefore(currentEnd) && newEnd.isAfter(currentStart)) {
      // Handle overlap: split the existing entry if necessary
      if (newStart.isAfter(currentStart)) {
        acc.push({
          startDate: priceObj.startDate,
          endDate: newStart
            .clone()
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          price: priceObj.price,
        });
      }

      if (newEnd.isBefore(currentEnd)) {
        acc.push({
          startDate: newEnd
            .clone()
            .add(1, 'day')
            .format('YYYY-MM-DD'),
          endDate: priceObj.endDate,
          price: priceObj.price,
        });
      }
    } else {
      acc.push(priceObj);
    }

    return acc;
  }, []);

  // Add the new entry, whether modified or not
  updatedArray.push(newEntry);

  // Sort the array by startDate to maintain order
  return updatedArray.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));
};

export const getPriceForDateRange = (individualPriceOptions, startDate, endDate) => {
  if (startDate && endDate && individualPriceOptions && individualPriceOptions.length > 0) {
    const start = moment.isMoment(startDate) ? startDate : moment(startDate);
    const end = moment.isMoment(endDate) ? endDate : moment(endDate);

    for (const priceObj of individualPriceOptions) {
      const objStartDate = moment(priceObj.startDate);
      const objEndDate = moment(priceObj.endDate);

      if (start.isSameOrAfter(objStartDate, 'day') && end.isSameOrBefore(objEndDate, 'day')) {
        return {
          price: priceObj.price,
          name: priceObj.name,
          minimumNights: priceObj.minimumNights,
        };
      }

      if (start.isSame(end, 'day')) {
        if (start.isSame(objStartDate, 'day') || start.isSame(objEndDate, 'day')) {
          return {
            price: priceObj.price,
            name: priceObj.name,
            minimumNights: priceObj.minimumNights,
          };
        }
      }
    }
    return 0;
  } else {
    return 0;
  }
};

export const removeDaysPrice = (startDate, endDate, individualPriceOptions) => {
  const formattedStartDate = startDate?.format('YYYY-MM-DD');
  const formattedEndDate = endDate?.format('YYYY-MM-DD');

  let newPriceObjArr = [];

  individualPriceOptions.forEach(priceObj => {
    const { startDate: objStartDate, endDate: objEndDate, price } = priceObj;

    if (
      moment(formattedStartDate).isBetween(objStartDate, objEndDate, null, '[]') ||
      moment(formattedEndDate).isBetween(objStartDate, objEndDate, null, '[]')
    ) {
      if (moment(formattedStartDate).isAfter(objStartDate)) {
        newPriceObjArr.push({
          startDate: objStartDate,
          endDate: moment(formattedStartDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          price,
        });
      }

      if (moment(formattedEndDate).isBefore(objEndDate)) {
        newPriceObjArr.push({
          startDate: moment(formattedEndDate)
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          endDate: objEndDate,
          price,
        });
      }
    } else {
      newPriceObjArr.push(priceObj);
    }
  });

  return newPriceObjArr;
};

export function getLeastPrice(priceOptions) {
  let leastPrice = Infinity;
  for (const option of priceOptions) {
    if (option.price < leastPrice) {
      leastPrice = option.price;
    }
  }
  return leastPrice;
}

export const calculateInstallment = (paymentType, installments) => {
  if (paymentType === PAYMENT_TYPES.HALF_HALF) {
    return [{ mileStoneStatus: 'pending', percentage: 50 }];
  }

  if (paymentType === PAYMENT_TYPES.INSTALLMENTS && isArrayLength(installments)) {
    return installments.map(installment => {
      return { ...installment, mileStoneStatus: 'pending' };
    });
  }

  // Default return if paymentType doesn't match expected values
  return installments;
};

export const calculateInitialFee = initialFeePercentage => {
  if (initialFeePercentage == null || initialFeePercentage == 0) {
    return null;
  }

  const initialFee = [
    {
      label: INITIAL_FEE,
      percentage: initialFeePercentage,
      mileStoneStatus: 'pending',
    },
  ];

  return initialFee;
};

export function calculateInstallmentAmounts(installments = [], totalPrice) {
  return installments.map(installment => {
    const amount = (parseFloat(installment.percentage) / 100) * totalPrice;
    return {
      ...installment,
      amount: amount, // rounding to 2 decimal places
    };
  });
}

export const encryptString = (plainText, secretKey) => {
  let encryptedText = '';
  for (let i = 0; i < plainText.length; i++) {
    const plainCharCode = plainText.charCodeAt(i);
    const keyCharCode = secretKey.charCodeAt(i % secretKey.length);
    const encryptedCharCode = (plainCharCode + keyCharCode) % 256;
    encryptedText += String.fromCharCode(encryptedCharCode);
  }
  return encryptedText;
};

export const checkPaymentFailed = pathName => {
  if (pathName && pathName.includes('payment-failed')) {
    return { paymentFailed: true };
  }
  return null;
};

export const getStartedMilestones = installments => {
  return (
    installments.find(
      installment =>
        installment &&
        typeof installment === 'object' &&
        'mileStoneStatus' in installment &&
        installment.mileStoneStatus === 'started'
    ) || null
  );
};

export function checkMilestoneStatus(installments = []) {
  return installments?.some(
    installment =>
      installment.mileStoneStatus === 'started' ||
      installment.mileStoneStatus === 'paymentCompleted'
  );
}

export function getUniqueNamesAndColors(priceOptions = []) {
  return [
    ...new Map(
      priceOptions
        .filter(({ name, color, price }) => name && color && price !== undefined)
        .map(({ name, color, price }) => [
          name,
          { color, priceInEuros: `€${(price / 100).toFixed(2)}` },
        ])
    ),
  ].map(([name, { color, priceInEuros }]) => ({ name, color, priceInEuros }));
}

export function getPaymentType(transaction) {
  if (transaction?.listing?.attributes?.publicData?.paymentType) {
    return transaction?.listing?.attributes?.publicData?.paymentType || null;
  }
  return null;
}

export function isAllMilestonesPayoutCompleted(transaction) {
  const { initialFee, installments } = transaction.attributes.metadata;

  const checkPayoutCompleted = arr => arr.every(item => item.mileStoneStatus === 'payoutCompleted');

  const isInitialFeeCompleted = checkPayoutCompleted(initialFee || []);
  const areInstallmentsCompleted = checkPayoutCompleted(installments || []);

  return isInitialFeeCompleted && areInstallmentsCompleted;
}

export const generateDarkColor = () => {
  const randomValue = () => Math.floor(Math.random() * 100); // Lower values for darkness
  const randomHighValue = () => Math.floor(Math.random() * 155 + 100); // Ensure one higher value for color variation

  // Randomly assign the higher value to one of the RGB channels
  const r = randomValue();
  const g = randomValue();
  const b = randomValue();

  const rgbArray = [r, g, b];
  rgbArray[Math.floor(Math.random() * 3)] = randomHighValue();

  return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
};

export const extractLineItemDetails = (allLineItems) => {
  if (!Array.isArray(allLineItems) || allLineItems.length === 0) {
    return null;
  }

  const { installments, totalPrice } = allLineItems[0];
  return {
    lineItemInstallments: installments || [],
    lineItemTotalPrice: totalPrice , 
  };
};

export function checkPaymentStatus(metadata) {
  let result = {};

  // Ensure metadata is valid
  if (!metadata || typeof metadata !== "object") return result;

  // Default empty arrays to prevent TypeErrors
  const initialFees = Array.isArray(metadata.initialFee) ? metadata.initialFee : [];
  const installments = Array.isArray(metadata.installments) ? metadata.installments : [];

  // Merge all fees
  const allFees = [...initialFees, ...installments];

  // Check if all milestones are paid
  const allPaid = allFees.length > 0 && allFees.every(fee => fee.mileStoneStatus === "paid");

  if (allPaid) {
      return { key: "mileStone", value: "Payment" };
  }

  // Check initialFee array
  if (initialFees.length > 0) {
      const initialFeeStatus = initialFees[0].mileStoneStatus;
      if (initialFeeStatus === "pending") {
          return { key: "initialFee", value: "Down Payment" };
      } else if (initialFeeStatus === "paid" && installments.length > 0) {
          // Proceed to check milestones
          if (installments[0].mileStoneStatus === "pending") {
              return { key: "mileStone", value: "Payment" };
          }
      }
  }

  return result; // Default empty object if no conditions are met
}


export function checkPaymentComplete(metadata) {
  // Ensure metadata is valid
  if (!metadata || typeof metadata !== "object") return false;

  // Default empty arrays to prevent TypeErrors
  const initialFees = Array.isArray(metadata.initialFee) ? metadata.initialFee : [];
  const installments = Array.isArray(metadata.installments) ? metadata.installments : [];

  // Merge all fees
  const allFees = [...initialFees, ...installments];

  // Check if any fee has mileStoneStatus as "pending"
  const hasPendingFee = allFees.some(fee => fee.mileStoneStatus === "pending");

  return hasPendingFee;
}


export function calculatePrice(metadata) {
  let price = 0;

  if (metadata.initialFee && metadata.initialFee.length > 0) {
      const initialFee = metadata.initialFee[0];
      if (initialFee.mileStoneStatus === "pending") {
          price = (initialFee.percentage / 100) * metadata.totalPrice;
          return price; // Return early if initial fee is pending
      }
  }

  if (metadata.installments && metadata.installments.length > 0) {
      for (let installment of metadata.installments) {
          if (installment.mileStoneStatus === "pending") {
              price = (installment.percentage / 100) * metadata.totalPrice;
              return price;
          }
      }
  }

  return price; // Default 0 if all are paid
}


export function checkMileStoneExits(listing) {
  const hasInitialFee = listing.initialFee && listing.initialFee.length > 0;
  const hasInstallments = listing.installments && listing.installments.length > 0;
  
  return hasInitialFee && hasInstallments ;
}


export function checkMultipleIssuePaymentLinks(items) {
  const count = items.filter(item => item.transition === 'transition/issue-payment-link').length;
  return count > 1 ? true : false;
}