export const subscriptionFAQ = [
  {
    label: 'Is there a free trial?',
    body:
      'No, Choose & Cheers does not offer a free trial. We provide detailed and up-to-date pricing information from venues, which would take planners countless hours to gather through emails, calls, and follow-ups.',
  },
  {
    label: 'Can I change my plan later?',
    body:
      'Your subscription will include the Warm-Up Plan for one year. You can unlock the Pro Plan and receive a discount if you book 4 or more venues through Choose & Cheers within that year.',
  },
  {
    label: 'What is the cancellation policy?',
    body:
      'You can cancel your subscription at any time before it renews (1 year from the payment date). If you cancel during your active subscription, you will retain access to the platform until the end of the paid period.',
  },
];

export const INITIAL_FEE = 'initialFee';

export const RESERVATION_TYPES = {
  RESERVATION: 'reservation',
  PRERESERVATION: 'prereservation',
};
export const PAYMENT_TYPES = {
  HALF_HALF: 'half/half',
  INSTALLMENTS: 'installments',
  ONCE: 'once',
};

export const MILESTONE_STATUS = {
  PENDING: 'pending',
  STARTED: 'started',
  PAYMENT_COMPLETED: 'paymentCompleted',
  PAYOUT_COMPLETED: 'payoutCompleted',
};

export const MILESTONE_ACTION = {
  START: 'start',
  PAYMENT: 'payment',
};

export const USER_TYPES = {
  PLANNER: 'planner',
  VENUE: 'venue',
};

export const VISHAL = '14021997';
