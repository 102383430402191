import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { createSessionApi, showTransactionDetailsApi } from '../../util/api';
import { getQueryParams } from '../../util/dataExtractor';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PaymentSuccessPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/PaymentSuccessPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/PaymentSuccessPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/PaymentSuccessPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/PaymentSuccessPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/PaymentSuccessPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/PaymentSuccessPage/FETCH_TRANSITIONS_ERROR';

export const CREATE_URL_REQUEST = 'app/PaymentSuccessPage/CREATE_URL_REQUEST';
export const CREATE_URL_SUCCESS = 'app/PaymentSuccessPage/CREATE_URL_SUCCESS';
export const CREATE_URL_ERROR = 'app/PaymentSuccessPage/CREATE_URL_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  fetchTransitionsError: null,
  isSuccess:null,
  url:null,
  urlInProgress:false,
  urlError:null,
  fetchTransitionsInProgress:false,
  fetchTransitionsError:null
};

export default function PaymentSuccessPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
          return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
          return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
          console.error(payload); // eslint-disable-line
          return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };  

    case CREATE_URL_REQUEST:
      return { ...state, urlInProgress: true, urlError: null };
    case CREATE_URL_SUCCESS: {
      return { ...state, urlInProgress: false, url:payload, };
    }
    case CREATE_URL_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, urlInProgress: false, urlError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //


// ================ Action creators ================ //
const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const createUrlRequest = () => ({ type: CREATE_URL_REQUEST });
const createUrlSuccess = response => ({
  type: CREATE_URL_SUCCESS,
  payload: response,
});
const createUrlError = e => ({ type: CREATE_URL_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const getImageVariants = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, queryParams, config) => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  const txQueryParams = {
    id,
    include: [
      'customer',
      'customer.profileImage',
      'provider',
      'provider.profileImage',
      'listing',
      'listing.currentStock',
      'listing.images',
      'booking',
    'reviews',
      'reviews.author',
      'reviews.subject',
    ],
    ...getImageVariants(config?.layout?.listingImage),
  };

  try {
    // Fetch transaction details
    const result = await showTransactionDetailsApi({ txQueryParams ,queryParams });
    const apiResponse = result?.data?.response;
    if (!apiResponse) throw new Error('Invalid response from transaction API');

    // Extract and process listing and transaction data
    const listingId = listingRelationship(apiResponse)?.id;
    const entities = updatedEntities({}, apiResponse?.data);
    const listingRef = { id: listingId, type: 'listing' };
    const transactionRef = { id: new UUID(id), type: 'transaction' };

    const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
    if (!denormalised) throw new Error('Failed to denormalize entities');

    // Sanitize and dispatch actions
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };

    dispatch(addMarketplaceEntities(apiResponse, sanitizeConfig));
    dispatch(fetchTransactionSuccess(apiResponse));

    return result?.data;
  } catch (error) {
    const processedError = storableError(error);
    dispatch(fetchTransactionError(processedError));
    console.error('Error fetching transaction:', processedError);
    throw processedError;
  }
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};


export const createPaymentUrl = sessionData => async (dispatch, getState, sdk) => {
  dispatch(createUrlRequest());
  try {
    const resp = await createSessionApi(sessionData);
    const url = resp?.url;
    dispatch(createUrlSuccess(url));
    return url
  } catch (error) {
    dispatch(createUrlError(error));
  }
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params, search, config) => (dispatch, getState) => {
  const txId = params.id;

  const url = window && typeof window != "undefined" && window?.location?.href;
  const queryParams = getQueryParams(url, ['isSuccess', 'isReservationSuccess']);
  console.log('queryParams', queryParams)
  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, queryParams, config)),
    dispatch(fetchNextTransitions(txId)),
    dispatch(fetchCurrentUser()),
  ]);
};
