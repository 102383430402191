import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';
import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, booking ,isMileStone,listing } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;

  const translationKey = isNightly && isMileStone
    ? 'OrderBreakdown.baseUnitPricePerNight'
    : isNightly
    ? 'OrderBreakdown.baseListingPrice'
    : isDaily
    ? 'OrderBreakdown.baseUnitQuantity'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  let numberOfNights = 0;

  // Check if booking and attributes exist
  if (booking && booking?.attributes) {
    const { start, end, displayStart, displayEnd } = booking.attributes;
    const localStartDate = moment(displayStart || start);
    const localEndDate = moment(displayEnd || end);

    // Calculate the difference in days
    numberOfNights = localEndDate.diff(localStartDate, 'days');
  } else {
    numberOfNights = 0;
  }

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
   const listingPriceAmount = listing?.attributes?.price?.amount * quantity;
   const actualTotalPrice = new Money(listingPriceAmount ,listing?.attributes?.price?.currency)
    const formatedActualTotalPrice = listingPriceAmount ? formatMoney(intl, actualTotalPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  return quantity && total ? (
    <>
    
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
      <FormattedMessage id="OrderBreakdown.NumberOfNights"/>
      </span>
      <span className={css.itemValue}>{quantity}</span>
    </div>
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.totalQuantityPrice"/>
      </span>
      <span className={css.itemValue}>{formatedActualTotalPrice}</span>
    </div>
    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
