import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';

import css from './OrderBreakdown.module.css';
import { isPrereservation } from '../../util/dataExtractor';
import { isArrayLength } from '../../util/genericHelpers';

const LineItemInstallmentsMayBe = props => {
  const { installments, intl, listing } = props;
  const isReservation = isPrereservation(listing);
  const { prereservationDays, initialFee } = listing?.attributes?.publicData;

  return (
    <>
      {installments && installments.length > 0 && (
        <div className={css.installmentsSection}>
          <h3 className={css.installmentsHeader}>
            <FormattedMessage id="OrderBreakdown.installments" />
          </h3>
          {isReservation ? (
            <div className={css.installmentItem}>
              <div className={css.installmentRow}>
                <div className={css.installmentLabel}>
                  <FormattedMessage id="OrderBreakdown.installmentsHoldText" />
                </div>
              </div>
              <div className={css.installmentRow}>
                <div className={css.installmentStatus}>
                  <FormattedMessage id="OrderBreakdown.installmentsFreeText" />
                </div>
                <div className={css.installmentPercentage}>{`For ${prereservationDays} Days`}</div>
              </div>
            </div>
          ) : null}

          {isArrayLength(initialFee) ? (
            <div className={css.installmentItem}>
              <div className={css.installmentRow}>
                <div className={css.installmentLabel}>
                  <FormattedMessage id="OrderBreakdown.installmentsDownPaymentText" />
                </div>
              </div>
              <div className={css.installmentRow}>
                <div className={css.installmentStatus}>{initialFee[0]?.percentage}%</div>
                <div className={css.downPayment}>
                  <FormattedMessage id="OrderBreakdown.installmentsDueUponText" />
                </div>
              </div>
            </div>
          ) : null}
          {installments.map((installment, index) => (
            <div key={index} className={css.installmentItem}>
              <div className={css.installmentRow}>
                <div className={css.installmentLabel}>{installment.label || 'Milestone'} </div>
              </div>
              <div className={css.installmentRow}>
                <div className={css.installmentStatus}>{installment.percentage}%</div>
                <div className={css.installmentPercentage}>${installment.amount / 100}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

LineItemInstallmentsMayBe.propTypes = {
  intl: intlShape.isRequired,
  installments: arrayOf(
    shape({
      label: string.isRequired,
      description: string.isRequired,
      mileStoneStatus: string.isRequired,
      percentage: number.isRequired,
    })
  ).isRequired,
};

export default LineItemInstallmentsMayBe;
