import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from './data';
import { RESERVATION_TYPES, USER_TYPES } from './enums';
import moment from 'moment';

export function getUserDetails(user) {
  if (!user?.id) return null;
  // Combine first and last names using optional chaining and nullish coalescing
  const fullName = user?.attributes?.profile?.firstName
    ? `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`
    : user?.attributes?.profile?.publicData?.fullName;

  // Use optional chaining and nullish coalescing for profile image
  const profileImage =
    user?.profileImage?.attributes?.variants?.default?.url ||
    user?.profileImage?.attributes?.variants?.['square-small2x']?.url ||
    null; // Set to null if no image variants found

  // Use optional chaining and nullish coalescing for email
  const email = user?.attributes?.email || user?.attributes?.profile?.publicData?.email;

  // Extract ID using optional chaining and nullish coalescing
  const id = user?.id?.uuid;

  const userSubscriptionDetails = user?.attributes?.profile?.privateData?.subscriptionData || {};
  const firstName = user?.attributes?.profile?.firstName;
  const hasDefaultPaymentMethod =
    user?.id &&
    ensureStripeCustomer(user.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(user.stripeCustomer.defaultPaymentMethod).id;
  // Extract firstName using optional chaining (already defined earlier)
  const { displayName } = user?.attributes?.profile || {};
  // Return object using destructuring assignment and computed property for clarity
  return {
    fullName,
    profileImage,
    email,
    id,
    firstName,
    userSubscriptionDetails,
    hasDefaultPaymentMethod,
    displayName,
  };
}

/**
 * Returns the price details of a product.
 *
 * @param {Object} product - The product object.
 * @param {number} [product.unit_amount=0] - The price amount in cents.
 * @param {string} [product.currency='USD'] - The currency code.
 *
 * @returns {{price: string, currency: string}} - The product price details.
 */

export function getProductPriceDetails(product) {
  const priceAmount = product?.unit_amount || 0;
  const priceCurrency = product?.currency ?? 'EUR';

  // Map price nickname to human-readable name using a lookup table
  const priceName = 'Warm-Up Plan';
  const priceId = product?.id ?? 'unknown';
  const status = product?.status ?? 'unknown';

  return {
    price: Number(priceAmount / 100).toFixed(2),
    currency: priceCurrency,
    priceName,
    priceId,
    status,
  };
}

export function getClientSecret(setupIntent) {
  return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
}

export function getPaymentParams(currentUser, formValues) {
  const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
  const addressMaybe =
    addressLine1 && postal
      ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
      : {};
  const billingDetails = {
    name,
    email: ensureCurrentUser(currentUser).attributes.email,
    ...addressMaybe,
  };

  const paymentParams = {
    payment_method_data: {
      billing_details: billingDetails,
    },
  };

  return paymentParams;
}

export const isPrereservation = l => {
  return l?.attributes?.publicData?.reservationType === RESERVATION_TYPES.PRERESERVATION;
};

export const getReservationType = currentListing => {
  if (!currentListing || !currentListing?.id) {
    return null;
  }
  return currentListing?.attributes?.publicData?.reservationType;
};

export const getDueDate = transaction => {
  if (!transaction || !transaction?.id) {
    return null;
  }
  const startDate = transaction?.booking?.attributes?.start;
  const previousDate = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z').subtract(1, 'days');
  const formattedPreviousDate = previousDate.format('DD MMM, YYYY');
  return formattedPreviousDate;
};


export const isWeddingPlanner = user => {
  return user?.attributes?.profile?.publicData?.userType === USER_TYPES.PLANNER;
};

export const getQueryParams = (url, params = []) => {
  try {
    // Fix malformed query strings with multiple '?'
    const fixedUrl = url.replace(/\?(?=.*\?)/, '&'); // Replace subsequent `?` with `&`

    const urlParams = new URL(fixedUrl).searchParams;

    // Build an object dynamically with all specified parameters
    const result = {};
    params.forEach((param) => {
      const paramValue = urlParams.get(param);
      if (paramValue === 'true') {
        result[param] = true;
      } else if (paramValue === 'false') {
        result[param] = false;
      } else {
        result[param] = paramValue;
      }
    });

    return result;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null; // Return null if URL parsing fails
  }
};

export const getListingCurrency = listing => {
  if (!listing || !listing?.id) {
    return 'EUR';
  }
  return listing?.attributes?.price?.currency;
};

export const getListingPrice = listing => {
  if (!listing || !listing?.id) {
    return 'EUR';
  }
  return listing?.attributes?.price?.amount/100;
};

export const getListingTitle = listing => {
  if (!listing || !listing?.id) {
    return null;
  }
  return listing?.attributes?.title;
};
export const getFirstAvailableMinimumNights = individualPriceOptions => {
  for (const option of individualPriceOptions) {
    if (option.minimumNights) {
      return Number(option.minimumNights);
    }
  }
  return null; // Return null if no minimumNights is found
};