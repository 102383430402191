import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';
import moment from 'moment';

import css from './OrderBreakdown.module.css';

const ListingPriceMaybe = props => {
  const { lineItems, code, intl, booking ,listing } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = 'OrderBreakdown.baseListingPrice'
   

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  

  const listingPrice = listing?.attributes?.price;
  const unitPrice = listingPrice ? formatMoney(intl, listingPrice) : null;
  return unitPrice && listingPrice ? (
    <>
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice }} />
      </span>
      <span className={css.itemValue}>{unitPrice}</span>
    </div>
    </>
  ) : null;
};

ListingPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default ListingPriceMaybe;
